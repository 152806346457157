.Dice__Wrapper {
    padding-right: 40px;
    padding-left: 40px;

}

.Dice__Text {
    border: 4px;
    border-style: solid;
    padding-left: 30px;
    padding-right: 30px;
}

.Dice_Text_Red{
    border: 4px;
    border-style: solid;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 20px;
    padding-bottom: 20px;
    color: red;
    background-color: rgba(255, 0, 0, 0.10);
}

.Dice_Text_Green{
    border: 4px;
    border-style: solid;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 20px;
    padding-bottom: 20px;
    color: green;
    background-color: rgba(0, 128, 0, 0.10);
}

.Dice_Text_Black{
    border: 4px;
    border-style: solid;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 20px;
    padding-bottom: 20px;
    color: white;
    background-color: rgba(0, 0, 0, 0.10);
}

.Dice_Text_Yellow{
    border: 4px;
    border-style: solid;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 20px;
    padding-bottom: 20px;
    color: rgb(255, 234, 0);
    background-color: rgba(255, 255, 0, 0.10);
}

.Dice_Text_Blue{
    border: 4px;
    border-style: solid;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 20px;
    padding-bottom: 20px;
    color: Blue;
    background-color: rgba(0, 0, 255, 0.10);
}
